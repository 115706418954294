.header-container {
    //margin-top: 0px;
    background-color: rgb(194, 207, 213);
    display: flex;
    flex-direction: column;
    color: Black;

    .header-logo {
        flex-grow: 1;
    }

    .header-right {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-content: center;

        .header-description {
            font-family: 'Lane';
        }
    }
}