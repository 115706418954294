@import '../../App.scss';

.contact-container {
  min-height: 65vh;

  a {
    text-decoration: none;
    color: black;
  }

  a:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .contact-catchphrase {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .contact-text {
    max-width: 600px;
  }

  .contact-link-line {
    text-align: justify;
    display: flex;
    align-items: center;
    padding: 10px 30px;
    margin: 10px;
    border-radius: 30px;
    text-decoration: none;

    .contact-link-line-icon {
      margin-right: 10px;
      color: $icon;
    }
  }

  .contact-link-line:hover {
    background-color: $background-hover;
    .contact-link-line-icon {
      color: $icon-hover;
      margin-right: 10px;
    }
  }
}
