@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200&family=Source+Code+Pro:wght@200&display=swap');

//Palette
$icon: rgb(194, 207, 213);
$icon-hover: rgb(95, 132, 149);
$background-hover: rgba(194, 207, 213, 0.171);
$border: rgba(0, 0, 0, 0.12);
.App {
  text-align: center;
  position: relative;
  min-height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
  font-family: 'Lane';
  font-size: 1.16667rem;
  height: 100%;
}

.tab-item {
  margin-top: 10px;

  .centered-item {
    display: flex;
    justify-content: space-evenly;
    align-content: center;
  }
}

.content {
  padding-bottom: 2.5rem;
}
