.footer-container {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    //padding: 1rem;
    //background-color: #efefef;
    text-align: center;


    height: 2.5rem;
    font-size: 14px;
    display: flex;
    align-content: center;
    justify-content: center;

    .mentionsLegalesCTA {
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }

    }
}